<template>
  <div>
    <b-table
      small
      hover
      :fields="fields"
      :items="fila_espera"
      stacked="md"
      responsive
      show-empty
    >
      <template #empty>
        <div class="text-center">👩‍⚕️ Nenhum paciente na fila de espera.</div>
      </template>

      <template #cell(horario)="row">
        <b-form-input
          class="text-center"
          size="sm"
          v-model="row.item.horario"
          :state="validateState({ campo: 'horario', index: row.index })"
          type="time"
              locale="br"

          step="0"
          :disabled="row.item.agendado == 1"
          @input="onChange(row.item)"
          @keydown.enter="onSalvar(row.item)"
        ></b-form-input>
      </template>

      <template #cell(paciente)="row">
        <b-form-input
          class="text-center"
          size="sm"
          placeholder="Paciente"
          v-model="row.item.paciente"
          :state="validateState({ campo: 'paciente', index: row.index })"
          :disabled="row.item.agendado == 1"
          @input="onChange(row.item)"
          @keydown.enter="onSalvar(row.item)"
        ></b-form-input>
      </template>

      <template #cell(telefone)="row">
        <b-form-input
          class="text-center"
          :disabled="row.item.agendado == 1"
          :readonly="row.item.agendado == 1"
          size="sm"
          placeholder="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="row.item.telefone"
          :state="validateState({ campo: 'telefone', index: row.index })"
          @input="onChange(row.item)"
          @keydown.enter="onSalvar(row.item)"
        ></b-form-input>
      </template>

      <template #cell(agendado)="row">
        <i class="fas fa-check" v-if="row.item.agendado == 1"></i>
        <i class="fas fa-times" v-else></i>
      </template>

      <template #cell(actions)="row">
        <div class="d-flex">
          <b-btn
            :disabled="row.item.agendado == 1"
            size="sm"
            class="mx-1"
            variant="primary"
            @click="agendar(row.item)"
            v-if="!row.item._rowStatus && user.isAdmin"
            >{{ row.item.agendado == 1 ? "Agendado" : "Agendar" }}</b-btn
          >
          <b-btn
            size="sm"
            class="mx-1 text-nowrap"
            variant="primary"
            @click="onSalvar(row.item)"
            v-if="row.item._rowStatus"
          >
            <i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn
            v-if="row.item._rowStatus != 'updated' && !row.item.agendado"
            size="sm"
            class="mx-1"
            @click="removeEspera(row.item)"
            variant="transparent"
            ><i class="fas fa-trash"></i
          ></b-btn>

          <b-btn
            v-if="row.item._rowStatus === 'updated'"
            size="sm"
            class="mx-1"
            @click="desfazer(row.item)"
            variant="transparent"
            ><i class="fas fa-undo"></i
          ></b-btn>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// import {uuid} from 'vue-uuid'
import Validadores from "../../helpers/Validadores";

export default {
  components: {},
  props: {
    filaEsperaProp: {
      type: Array,
      default: () => [],
    },
    fila_espera_max: { type: Number },
  },
  mounted() {
    this.$forceUpdate();
    if (this.filaEsperaProp.length > 0) {
      this.filaEsperaProp.forEach((fila) => {
        this.fila_espera.push(Object.assign({}, fila));
      });
    }
  },
  data() {
    return {
      fields: [
        { key: "horario", label: "Horário", class: "text-center" },
        { key: "paciente", label: "Paciente", class: "text-center" },
        { key: "telefone", label: "Telefone", class: "text-center" },
        { key: "agendado", label: "Agendado", class: "text-center" },
        { key: "actions", label: "", class: "text-center" },
      ],
      fila_espera: [],
      hasErrors: [],
    };
  },
  watch: {
    filaEsperaProp() {
      let adicionando = this.fila_espera.filter(
        (fila) => fila._rowStatus == "added"
      );
      // console.log("adicionando", adicionando);
      this.fila_espera = [];
      if (this.filaEsperaProp.length > 0) {
        this.filaEsperaProp.forEach((fila) => {
          this.fila_espera.push(Object.assign({}, fila));
        });
      }
      if (adicionando) {
        this.fila_espera.push(...adicionando);
      }
      //   this.fila_espera.push(adicionando)
    },
    // fila_espera:{
    //   deep: true,
    //   handler(newVal){
    //     if
    //   }
    // }
  },
  methods: {
    addRowEspera() {
      // if (
      //   (!isNaN(this.fila_espera_max) ? this.fila_espera_max : 0) ==
      //   this.fila_espera.length
      // ) {
      //   this.showToast("error", "A fila de espera já está cheia.", 2800);
      // } else {
        this.fila_espera.push({
          // id: v4(),
          horario: "",
          paciente: "",
          telefone: "",
          agendado: 0,
          _rowStatus: "added",
        });
      // }
    },
    validateState(campo) {
      let hasError = this.hasErrors.find(
        (x) => x.campo.campo == campo.campo && x.campo.index == campo.index
      );
      //console.log('hasErrors', this.hasErrors)
      // console.log('hasErrir', hasError)
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      //console.log(campo)
      let hasError = this.hasErrors.find(
        (x) => x.campo.campo == campo.campo && x.campo.index === campo.index
      );
      // console.log('hasError', hasError)
      if (!hasError) {
        this.hasErrors.push({ campo, state });
        //console.log(this.hasErrors)
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    validar(index = "") {
      this.$forceUpdate();
      this.addOrUpdateError(
        { campo: "horario", index },
        !Validadores.string.IsNullOrEmpty(this.fila_espera[index].horario)
      );
      this.addOrUpdateError(
        { campo: "paciente", index },
        !Validadores.string.IsNullOrEmpty(this.fila_espera[index].paciente)
      );
      this.addOrUpdateError(
        { campo: "telefone", index },
        Validadores.string.validaTelefone(this.fila_espera[index].telefone)
      );
      // console.log(this.hasErrors.filter((x) => x.state == true));
      return this.hasErrors.filter((x) => x.state == true).length >= 3;
    },
    async onSalvar(_espera) {
      // if (
      //   (this.fila_espera_max ? this.fila_espera_max : 0) ==
      //   this.fila_espera.filter((fila) => !fila._rowStatus).length
      // ) {
      //   this.showToast("error", "A fila de espera já está cheia.", 2800);
      //   return;
      // }

      let index = this.fila_espera.indexOf(_espera);
      let $espera = Object.assign({}, _espera);

      if (this.validar(index)) {
        this.$set(this.fila_espera[index], "_rowStatus", null);
        delete this.fila_espera[index]._rowStatus;

        // console.log("onSave", espera);
        if ($espera._rowStatus) {
          if ($espera._rowStatus == "added") {
            this.$emit("paciente:added", this.fila_espera[index]);
          }

          if ($espera._rowStatus == "updated") {
            // console.log("editando: ", { ...this.fila_espera[index], index });
            this.$emit("paciente:updated", {
              ...this.fila_espera[index],
              index,
            });
          }
        }
      }
    },
    async removeEspera(espera) {
      let index = this.fila_espera.indexOf(espera);
      // console.log('index correto: ', index)
      if (index.toString() != "") {
        if (!espera._rowStatus) {
          await this.toastConfirmacao(
            () => {
              this.$emit("paciente:removed", index);
            },
            () => {},
            "Atenção!",
            "Tem certeza que deseja excluir este paciente da fila de espera? Isso não poderá ser desfeito."
          );
        } else {
          this.fila_espera.splice(index, 1);
        }
      }
    },
    async desfazer(espera) {
      let index = this.fila_espera.indexOf(espera);
      // console.log("index correto: ", index);
      if (index.toString() != "") {
        await this.toastConfirmacao(
          async () => {
            this.$set(
              this.fila_espera,
              index,
              Object.assign({}, this.filaEsperaProp[index])
            );
          },
          () => {},
          "Atenção!",
          "Tem certeza que deseja desfazer as alterações? Não será possível recupera-las!",
          "",
          "Sim. Desfazer!"
        );
      }
    },
    onChange(espera) {
      let index = this.fila_espera.indexOf(espera);

      this.validar(index);
      if (typeof index != "undefined") {
        if (espera._rowStatus !== "added") {
          if (this.filaEsperaProp[index]) {
            for (const key in this.filaEsperaProp[index]) {
              // console.log(
              //   this.filaEsperaProp[index][key],
              //   this.fila_espera[index][key]
              // );
              if (
                this.filaEsperaProp[index][key] != this.fila_espera[index][key]
              ) {
                this.$set(this.fila_espera[index], "_rowStatus", "updated");
                break;
              }
              this.$set(this.fila_espera[index], "_rowStatus", null);
            }
          }
        }
      }
    },
    agendar(espera) {
      // console.log(" agendou", espera);
      let index = this.fila_espera.indexOf(espera);
      // console.log(index);
      if (!espera._rowStatus && index.toString() != "" && index >= 0) {
        this.$set(this.fila_espera[index], "agendado", 1);
        this.$emit("paciente:agendado", { index, ...espera });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
